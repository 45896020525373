<template>
  <form-dialog v-model="is_open" :title="title" width="450px" hide-submit content-class="max-400">
    <template v-slot:content>
      <v-skeleton-loader :loading="is_loading" type="article">
        <div v-html="content"></div>
      </v-skeleton-loader>
      <error-box :error="error"></error-box>
    </template>
  </form-dialog>
</template>

<script>
import { blobRequest } from "../../_helpers";

export default {
  name: "support_dialog",
  props: {
    title: {
      type: String,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    is_open: false,
    error: null,
    content: null,
    is_loading: true,
  }),
  methods: {
    open() {
      this.is_open = true;

      if (!this.content) this.loadPage();
    },
    loadPage() {
      var self = this;
      self.is_loading = true;
      self.content = "";

      blobRequest
        .getText(
          "/support/" + process.env.VUE_APP_LANG + "/" + self.page + ".html"
        )
        .then((data) => {
          self.content = data;
          self.is_loading = false;
        })
        .catch((error) => {
          self.error = error;
          self.is_loading = false;
        });
    },
  },
};
</script>