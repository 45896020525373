<template>
  <v-card outlined>
    <v-img
      @click="current_image = 0"
      :src="getImage(media.image)"
      height="170"
      v-bind:class="{
        pointer: media.image && !media.is_uploading && !media.error,
      }"
      class="grey lighten-2"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
      <v-overlay absolute :value="is_uploading">
        <v-progress-circular indeterminate size="50"></v-progress-circular>
      </v-overlay>
    </v-img>
    <Tinybox
      v-if="media.image && !media.is_uploading && !media.error"
      no-thumbs
      :images="getImages(media.image)"
      v-model="current_image"
    />
    <template v-if="!media.is_uploading && !media.error">
      <v-form ref="form" v-model="form" lazy-validation>
        <v-card-text class="pa-3 pb-0 pt-5">
          <v-text-field
            :label="$t('Title')"
            v-model="item.title"
            :disabled="is_saving"
            :rules="[(v) => !!v || $t('TitleMissing')]"
            required
            counter
            maxlength="40"
            outlined
          ></v-text-field>
          <v-textarea
            outlined
            v-model="item.description"
            :placeholder="$t('Optional')"
            no-resize
            hide-details
            rows="3"
            class="mt-3 mb-5"
            :disabled="is_saving"
            :label="$t('Description')"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="pt-0">
          <button-menu
            color="secondary"
            fab
            x-small
            dark
            top
            right
            :items="admin_menu"
            absolute
            btn-icon="$vuetify.icons.fasPen"
            btn-class="mt-6 mr-n2 edit-btn"
          ></button-menu>
          <v-spacer></v-spacer>
          <v-btn
            :loading="is_saving"
            small
            color="primary"
            class="text-none"
            @click="save"
            >{{ $t("Button.Save") }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </template>
    <template v-if="!media.is_uploading && media.error">
      <v-card-text style="color: red">{{ media.error }}</v-card-text>
    </template>
    <template v-if="media.is_uploading">
      <v-card-text>
        <v-progress-linear
          color="light-blue"
          height="10"
          :value="media.progress"
          striped
        ></v-progress-linear>
        <div class="text-center mt-7">{{ $t("UploadInProgress") }}</div>
      </v-card-text>
    </template>

    <photo-editor
      v-on:close="onEditClose"
      v-on:done="onEditDone"
      v-if="show_editor"
      :image="temp_image.original"
    ></photo-editor>

    <v-overlay absolute :value="is_deleting">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<i18n>
    {
    "en": {
    "Title": "Title",
    "Description": "Descrption",
    "Optional": "Optional",
    "Saved": "Description saved!",
    "PhotoSaved": "Photo saved!",
    "Handle": "Handle",
    "Edit": "Image editor",
    "Delete": "Delete image",
    "ConfirmDeletePhoto": "Are you sure you want to delete this photo?",
    "SuccessDelete": "Image deleted!",
    "UploadMedia": "Upload images",
    "TitleMissing": "Title is missing!",
    "UploadInProgress": "Upload in progress.. please wait"
    },
    "sv": {
    "Title": "Titel",
    "Description": "Beskrivning",
    "Optional": "Frivilligt",
    "Saved": "Beskrivningen sparades!",
    "PhotoSaved": "Bilden sparades!",
    "Handle": "Hantera",
    "Edit": "Redigera bild",
    "Delete": "Ta bort bild",
    "ConfirmDeletePhoto": "Är du säker på att du vill ta bort detta foto?",
    "SuccessDelete": "Bilden togs bort!",
    "UploadMedia": "Ladda upp bilder",
    "TitleMissing": "Titel saknas!",
    "UploadInProgress": "Bilden laddas upp.. vänligen vänta"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { albumsService } from "@/_services";
const PhotoEditor = () => import("../../global/PhotoEditor.vue");
import Tinybox from "vue-tinybox";

export default {
  name: "diary_image_manage",
  props: ["media"],
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
    admin_menu: function () {
      var data = [];

      if (!this.$isMobile)
        data.push({
          name: this.$t("Edit"),
          icon: "$vuetify.icons.imageEditOutline",
          click: this.editPhoto,
        });

      data.push({
        name: this.$t("Delete"),
        icon: "$vuetify.icons.trashCanOutline",
        click: this.deletePhoto,
      });

      return data;
    },
  },
  components: {
    "photo-editor": PhotoEditor,
    Tinybox,
  },
  data: () => ({
    current_image: null,
    form: false,

    is_saving: false,
    is_deleting: false,
    is_uploading: false,

    is_saved: false,
    error: null,

    item: {
      title: "",
      description: "",
    },
    temp_image: null,
    show_editor: false,
  }),
  created: function () {
    this.item.title = this.media.title;
    this.item.description = this.media.description;
  },
  methods: {
    ...mapActions({
      deleteMedia: "diary/albums/deleteMedia",
    }),
    save() {
      var self = this;

      self.is_saved = false;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        albumsService
          .updateMedia(self.media.id, {
            title: self.item.title,
            description: self.item.description,
          })
          .then(function () {
            self.is_saving = false;
            self.is_saved = true;
            self.$successNoty(self.$t("Saved"));
            self.$emit("updated", {
              title: self.item.title,
              description: self.item.description,
              id: self.media.id,
            });
          })
          .catch(function (error) {
            self.is_saving = false;
            self.error = error;
            self.$ajaxErrorNoty(error);
          });
      }
    },
    getImage(image) {
      if (typeof image === "string" || image instanceof String) return image;

      return this.$imagehelpers.mediaWidth(image, 400);
    },
    async deletePhoto() {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeletePhoto"));

      if (res) {
        self.is_deleting = true;
        self
          .deleteMedia(self.media.id)
          .then(function () {
            self.$successNoty(self.$t("SuccessDelete"));
            self.$emit("deleted", self.media.id);
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            self.is_deleting = false;
            self.error = error;
          });
      }
    },
    getImages(image) {
      return [image.original];
    },

    editPhoto() {
      var self = this;
      self.temp_image = self.media.image;
      this.show_editor = true;
    },
    onEditDone(data) {
      var self = this;

      self.is_uploading = true;
      albumsService
        .uploadEditedImage(self.media.id, data.blob)
        .then(function (response) {
          self.is_uploading = false;
          self.$emit("updated", {
            id: self.media.id,
            image: response.data.image,
          });
          self.$successNoty(self.$t("PhotoSaved"));
          self.show_editor = false;
        })
        .catch(function (error) {
          self.is_uploading = false;
          self.$ajaxErrorNoty(error);
          self.show_editor = false;
        });
    },
    onEditClose() {
      this.show_editor = false;
    },
  },
};
</script>

<style>
.media-header {
  word-break: normal !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ptro-holder-wrapper {
  z-index: 10;
}

.edit-btn {
  opacity: 0.9;
}
</style>