<template>
  <v-card elevation="1">
    <v-card-title class="pb-0" v-if="!$isMobile">
      {{ title }}
    </v-card-title>

    <v-card-title v-if="$isMobile">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn @click="sheet = true" depressed>
        <v-icon>$vuetify.icons.fasBars</v-icon>
      </v-btn>
    </v-card-title>

    <v-list v-if="!$isMobile">
      <v-list-item-group color="primary">
        <template v-for="(item, i) in items">
          <template v-if="!item.divider">
            <v-list-item :to="item.url" :key="i" :exact="item.exact">
              <v-list-item-avatar class="ml-n1" v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content :class="menuIconClass(item)">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <div v-if="item.show_description" class="global-list-desc">
                  {{ item.description }}
                </div>
              </v-list-item-content>
              <v-list-item-action v-if="item.badge">
                {{ item.badge }}
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="'div' + i" v-if="item.show_divider"></v-divider>
          </template>
          <v-divider :key="'divs' + i" v-if="item.divider"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>

    <v-bottom-sheet v-model="sheet" v-if="$isMobile">
      <v-list two-line subheader>
        <v-subheader>{{ title }}</v-subheader>
        <v-list-item
          :to="item.url"
          v-for="(item, i) in items"
          :key="i"
          @click="menuClick(item)"
        >
          <v-list-item-avatar v-if="item.icon">
            <v-icon class="grey lighten-2">{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="global-list-header">{{
              item.title
            }}</v-list-item-title>
            <div class="global-list-desc">{{ item.description }}</div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
export default {
  name: "card_menu",

  props: {
    items: {
      type: Array,
    },
    title: {
      type: String,
    },
  },
  data: () => ({
    sheet: null,
  }),
  methods: {
    menuClick(item) {
      this.sheet = false;

      if (item.click) item.click.call();
    },
    menuIconClass(item) {
      if (item && item.icon) return "ml-n1";
      return "";
    },
  },
};
</script>