<template>
  <div v-if="user">
    <button-menu
      icon
      :items="menu"
      right
      top
      absolute
      btn-icon="$vuetify.icons.dotsHorizontal"
      v-if="!btnList"
      :is-loading="is_deleting"
      btn-style="background-color: white"
    ></button-menu>

    <button-menu
      :items="menu"
      depressed
      small
      fab
      btn-icon="$vuetify.icons.dotsHorizontal"
      v-if="btnList"
      :is-loading="is_deleting"
      color="gray"
    ></button-menu>
    <template v-if="is_admin">
      <privacy-dialog
        v-on:updated="onUpdated"
        ref="privacyDialog"
      ></privacy-dialog>
      <date-dialog v-on:updated="onUpdated" ref="dateDialog"></date-dialog>
      <children-dialog
        v-on:updated="onChildrenUpdated"
        ref="childrenDialog"
      ></children-dialog>
      <category-dialog
        v-on:updated="onUpdated"
        ref="categoryDialog"
      ></category-dialog>
      <cover-dialog v-on:updated="onUpdated" ref="coverDialog"></cover-dialog>
    </template>

    <abuse-dialog ref="abuseDialog" v-if="!is_admin && user"></abuse-dialog>
  </div>
</template>

<i18n>
    {
    "en": {
    "Abuse": "Abuse",
    "Edit": "Edit post",
    "ConfirmDeletePost": "Are you sure you want to delete the post?",    
    "EditPrivacy": "Edit privacy",    
    "TagChildren": "Tag children",
    "TagCategories": "Tag categories",
    "EditPublishDate": "Edit publish date",
    "EditCover": "Edit cover"
    },
    "sv": {
    "Abuse": "Anmäl",
    "Edit": "Redigera inlägg",
    "ConfirmDeletePost": "Är du säker på att du vill ta bort inlägget?",    
    "EditPrivacy": "Redigera sekretess",    
    "TagChildren": "Tagga barn",
    "TagCategories": "Tagga kategorier",
    "EditPublishDate": "Redigera publiceringsdatum",
    "EditCover": "Ändra omslagsbild"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
const PrivacyDialog = () => import("../dialogs/PrivacyDialog.vue");
const EditDateDialog = () => import("../dialogs/EditDateDialog.vue");
const ChildrenSelectDialog = () =>
  import("../dialogs/ChildrenSelectDialog.vue");
const CategorySelectDialog = () =>
  import("../dialogs/CategorySelectDialog.vue");
const AbuseDialog = () => import("../dialogs/AbuseDialog.vue");
const CoverPickerDialog = () => import("../dialogs/BlogPostCoverManager.vue");

export default {
  name: "diary_post_menu",
  props: {
    post: {
      type: Object,
      required: true,
    },
    btnList: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
    menu: function () {
      var data = [];

      if (!this.is_admin) {
        data.push({
          name: this.$t("Abuse"),
          icon: "$vuetify.icons.alertCircleOutline",
          click: this.openAbuseDialog,
        });
      }

      if (this.is_admin) {
        data.push({
          name: this.$t("Edit"),
          icon: "$vuetify.icons.fileDocumentEditOutline",
          to: { name: "diary_blog_edit", params: { id: this.post.id } },
        });

        data.push({
          name: this.$t("EditCover"),
          icon: "$vuetify.icons.imageOutline",
          click: this.openCoverDialog,
        });

        data.push({
          name: this.$t("EditPrivacy"),
          icon: "$vuetify.icons.earth",
          click: this.openPrivacyDialog,
        });

        data.push({
          name: this.$t("EditPublishDate"),
          icon: "$vuetify.icons.alarm",
          click: this.openDateDialog,
        });

        data.push({
          name: this.$t("TagChildren"),
          icon: "$vuetify.icons.baby",
          click: this.openChildrenDialog,
        });

        data.push({
          name: this.$t("TagCategories"),
          icon: "$vuetify.icons.tagMultipleOutline",
          click: this.openCategoryDialog,
        });

        data.push({
          name: this.$t("Button.Delete"),
          icon: "$vuetify.icons.trashCanOutline",
          click: this.deleteAction,
        });
      }

      return data;
    },
  },
  components: {
    "privacy-dialog": PrivacyDialog,
    "date-dialog": EditDateDialog,
    "children-dialog": ChildrenSelectDialog,
    "category-dialog": CategorySelectDialog,
    "abuse-dialog": AbuseDialog,
    "cover-dialog": CoverPickerDialog,
  },
  data: () => ({
    is_deleting: false,
  }),
  methods: {
    ...mapActions({
      delete: "diary/news/delete",
    }),

    async deleteAction() {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeletePost"));

      if (res) {
        self.is_deleting = true;

        self
          .delete(self.post.id)
          .then(function () {
            self.$emit("deleted", self.post.id);
          })
          .catch(function (error) {
            self.answer_error = error;
            self.is_deleting = false;
          });
      }
    },

    openPrivacyDialog() {
      this.$refs.privacyDialog.openBlogPost(this.post);
    },
    openDateDialog() {
      this.$refs.dateDialog.editPostDate(this.post);
    },
    openChildrenDialog() {
      this.$refs.childrenDialog.openBlogPost(this.post);
    },
    openCategoryDialog() {
      this.$refs.categoryDialog.openBlogPost(this.post);
    },
    openAbuseDialog() {
      this.$refs.abuseDialog.abuseBlogPost(this.post);
    },
    openCoverDialog() {
      this.$refs.coverDialog.open(this.post);
    },
    onChildrenUpdated(media) {
      this.$emit("updatedChildren", media);
    },
    onUpdated(post) {
      this.$emit("updated", post);
    },
  },
};
</script>