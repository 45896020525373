<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="$t('TimelineSettings')"
    v-on:submit="save"
    max-width="450px"
  >
    <template v-slot:content>
      <div>Visa inlägg i tidslinjen från dessa händelser:</div>
      <v-card outlined>
        <v-list two-line class="pt-0 pb-0">
          <template v-for="(item, index) in items">
            <v-list-item :key="item.key">
              <v-list-item-content>
                <v-list-item-title
                  class="global-list-header mb-0"
                  v-text="item.title"
                ></v-list-item-title>
                <div
                  class="global-list-desc pt-1"
                  v-text="item.subtitle"
                ></div>
              </v-list-item-content>

              <v-list-item-action>
                <v-switch
                  dense
                  v-model="item.is_active"
                  inset
                  :disabled="is_saving"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>

            <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
          </template>
        </v-list>
      </v-card>
      <v-select
        :items="[10, 15, 20, 25, 30]"
        :label="$t('PageSize')"
        :disabled="is_saving"
        outlined
        v-model="pagesize"
        class="mt-8"
      ></v-select>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
    "TimelineSettings": "Timeline settings",
    "TimelineSettingsDesc": "Here you have the opportunity to select what kind of events that should be displayed in your timeline.",
    "BlogPostEvents": "Diary posts",
    "ChildEvents": "Child-events",
    "AlbumEvents": "Album-events",
    "BlogPostEventsDesc": "Show published posts from the diary",
    "ChildEventsDesc": "Events from child like birth, growth and progress",
    "AlbumEventsDesc": "Show uploaded images",
    "SettingsSaved": "Settings saved!",
    "PageSize": "Number of posts / page"
    },
    "sv": {
    "TimelineSettings": "Tidslinjeinställningar",
    "TimelineSettingsDesc": "Här har du möjlighet att välja vilken typ av händelser som ska visas i din tidslinje.",
    "BlogPostEvents": "Dagboksinlägg",
    "ChildEvents": "Barnhändelser",
    "AlbumEvents": "Albumhändelser",
    "BlogPostEventsDesc": "Visa publicerade inlägg från dagboken",
    "ChildEventsDesc": "Händelser kopplade till barn tex födelse, utveckling",
    "AlbumEventsDesc": "Visa uppladdade bilder",
    "SettingsSaved": "Inställningar sparades!",
    "PageSize": "Antal inlägg / sida"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "diary_timeline_settings_dialog",
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
      settings: (state) => state.diary.settings,
    }),
  },
  components: {},
  data: () => ({
    is_open: false,
    is_saving: false,

    items: [],
    pagesize: 25,
  }),
  created: function () {},
  methods: {
    ...mapActions({
      updateSettings: "diary/updateSettings",
    }),
    setItemsList() {
      this.items = [
        {
          title: this.$t("BlogPostEvents"),
          subtitle: this.$t("BlogPostEventsDesc"),
          key: "blogpost_events",
        },
        {
          title: this.$t("ChildEvents"),
          subtitle: this.$t("ChildEventsDesc"),
          key: "child_events",
        },
        {
          title: this.$t("AlbumEvents"),
          subtitle: this.$t("AlbumEventsDesc"),
          key: "album_events",
        },
      ];
    },

    open() {
      this.setItemsList();

      this.is_saving = false;
      this.is_open = true;

      var data = this.settings[this.$enums.DIARYSETTINGS.Feed];
      this.pagesize = data.pagesize;

      for (let i = 0; i < this.items.length; i++) {
        const obj = this.items[i];

        if (data && data[obj.key] == false) {
          obj.is_active = false;
        } else {
          obj.is_active = true;
        }
      }
    },
    getSettingsForSave() {
      var data = {
        pagesize: this.pagesize,
      };

      for (let i = 0; i < this.items.length; i++) {
        const obj = this.items[i];
        data[obj.key] = obj.is_active;
      }
      return data;
    },
    save() {
      var self = this;
      self.is_saving = true;

      self
        .updateSettings({
          name: self.$enums.DIARYSETTINGS.Feed,
          data: self.getSettingsForSave(),
        })
        .then(function () {
          self.is_saving = false;
          self.is_open = false;
          self.$successNoty(self.$t("SettingsSaved"));
          self.$emit("updated");
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
          self.is_saving = false;
        });
    },
  },
};
</script>