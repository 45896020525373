<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="$t('GuestbookSettings')"
    v-on:submit="save"
  >
    <template v-slot:content>
      <v-select
        :items="privacy_items"
        :label="$t('Privacy')"
        outlined
        item-text="name"
        item-value="value"
        v-model="privacy"
        :hint="$t('PrivacyHint')"
        persistent-hint
        class="mt-4"
        :disabled="is_saving"
      ></v-select>

      <v-checkbox
        v-model="pre_moderate_comments"
        :disabled="is_saving"
        :label="$t('PreModerate')"
      ></v-checkbox>

      <v-select
        :items="[10, 15, 20, 25, 30]"
        :label="$t('PageSize')"
        :disabled="is_saving"
        outlined
        v-model="pagesize"
        class="mt-4"
        hide-details
      ></v-select>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
      "GuestbookSettings": "Guestbook settings",
      "Privacy": "Who can read and write in the guestbook:",
       "PrivacyHint": "Users must Login before commenting",
      "PreModerate": "Preview the comment before publishing",
      "PageSize": "Number of comments / page",
      "SettingsUpdated": "Settings updated!"
    },
    "sv": {
      "GuestbookSettings": "Inställningar för gästboken",
      "Privacy": "Vem kan läsa och skriva i gästboken:",
      "PrivacyHint": "Inloggning krävs alltid för att kunna kommentera",
      "PreModerate": "Förhandsgranska kommentaren innan publicering",
      "PageSize": "Antal kommentarer / sida",
      "SettingsUpdated": "Inställningarna uppdaterades!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "guestbook_settings_dialog",
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      settings: (state) => state.diary.settings,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {},
  data: () => ({
    is_open: false,
    is_saving: false,
    privacy_items: [],

    pagesize: 25,
    privacy: 1,
    pre_moderate_comments: false,
  }),
  created: function () {
    var items = this.$objectToArray(this.$enums.DIARYPAGEPRIVACY);

    items.forEach((element) => {
      element.name = this.$t("PrivacyTypes." + element.key);
      this.privacy_items.push(element);
    });
  },
  methods: {
    ...mapActions({
      updateSettings: "diary/updateSettings",
    }),
    open() {
      this.is_saving = false;
      this.error = null;
      this.is_open = true;

      var data = this.settings[this.$enums.DIARYSETTINGS.Guestbook];

      if (data) {
        this.pagesize = data.pagesize;
        this.privacy = data.privacy;
        this.pre_moderate_comments = data.pre_moderate_comments;
      }
    },

    save() {
      var self = this;
      self.is_saving = true;

      var data = {
        pagesize: this.pagesize,
        privacy: this.privacy,
        pre_moderate_comments: this.pre_moderate_comments,
      };

      self
        .updateSettings({
          name: self.$enums.DIARYSETTINGS.Guestbook,
          data: data,
        })
        .then(function () {
          self.is_saving = false;
          self.is_open = false;
          self.$emit("updated");
          self.$successNoty(self.$t("SettingsUpdated"));
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
          self.is_saving = false;
        });
    },
  },
};
</script>