<template>
  <v-form ref="form" v-model="form" lazy-validation>
    <v-row>
      <v-col cols="12" md="12" class="mx-auto">
        <v-text-field
          :rules="[(v) => !!v || $t('TitleMissing')]"
          :loading="is_saving"
          required
          outlined
          v-model="post.header"
          :label="$t('Title')"
        ></v-text-field>

        <div class="text-right">
          <v-btn
            style="border-color: grey"
            small
            outlined
            @click="openGallery"
            class="text-none"
          >
            <v-icon left small>$vuetify.icons.fasImages</v-icon>
            {{ $t("PhotoAlbum") }}
          </v-btn>
        </div>
        <tinymce-editor
          :init="editor"
          :disabled="is_saving"
          :api-key="tinymceApiKey"
          v-model="post.text"
        ></tinymce-editor>
      </v-col>

      <v-col cols="12" md="6" xs="12">
        <v-autocomplete
          v-model="post.categories"
          :items="categories"
          chips
          outlined
          item-text="name"
          item-value="id"
          :no-data-text="$t('NoCategories')"
          :label="$t('Categories')"
          multiple
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="removeTagCategory(data.item)"
              >{{ data.item.name }}</v-chip
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" xs="12">
        <v-autocomplete
          v-model="post.children"
          :items="children"
          chips
          outlined
          :label="$t('TagChildren')"
          :no-data-text="$t('NoChildren')"
          item-text="name"
          item-value="id"
          multiple
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="removeTagChild(data.item)"
            >
              <v-avatar left>
                <v-img :src="$imagehelpers.childAvatar(data.item.avatar)"></v-img>
              </v-avatar>
              {{ data.item.name | capitalize }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <img :src="$imagehelpers.childAvatar(data.item.avatar)" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  data.item.name | capitalize
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" xs="12" class="pt-0 pb-0">
        <v-checkbox
          v-model="post.allow_comments"
          :label="$t('AllowComments')"
        ></v-checkbox>
      </v-col>

      <v-col cols="12" xs="12" md="6" class="mx-auto" v-if="error">
        <error-box :error="error" class="ma-4"></error-box>
      </v-col>

      <v-col cols="12" md="12" class="text-center pb-12">
        <v-btn
          v-if="!page.id"
          color="primary"
          @click="save"
          :loading="is_saving"
          class="text-none"
          >{{ $t("Button.Publish") }}</v-btn
        >
        <v-btn
          v-if="page.id"
          color="primary"
          @click="save"
          :loading="is_saving"
          class="text-none"
          >{{ $t("Button.Save") }}</v-btn
        >
      </v-col>
    </v-row>

    <media-picker
      v-if="diary"
      v-on:done="selectedMedia"
      ref="mediaPicker"
      :diary="diary.name"
      :max="5"
    ></media-picker>
  </v-form>
</template>

<i18n>
    {
    "en": {
    "Title": "Title",
    "Content": "Content",
    "Categories": "Categorize",
    "TagChildren": "Tag children",
    "TitleMissing": "Title is missing!",
    "CreateCategory": "Create category",
    "SuccessUpdate": "Post updated!",
    "SuccessCreate": "Post created!",
    "AllowComments": "Allow comments",
    "PhotoAlbum": "Photoalbum",
    "NoCategories": "No categories to select",
    "NoChildren": "No children to select"
    },
    "sv": {
    "Title": "Titel",
    "Content": "Innehåll",
    "Categories": "Kategorisera",
    "TagChildren": "Tagga barn",
    "TitleMissing": "Titel saknas!",
    "CreateCategory": "Skapa kategorin",
    "SuccessUpdate": "Inlägget uppdaterades!",
    "SuccessCreate": "Inlägget skapades!",
    "AllowComments": "Tillåt kommentering",
    "PhotoAlbum": "Fotoalbum",
    "NoCategories": "Finns inga kategorier att välja.",
    "NoChildren": "Finns inga barn att välja."
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import Editor from "@tinymce/tinymce-vue";
import { blogPostService } from "../../../_services";
import MediaPicker from "../../../components/diary/dialogs/MediaPickerDialog.vue";

export default {
  name: "diary_blog_form",
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      children: (state) => state.diary.children.list,
      categories: (state) => state.diary.categories.list,
      is_admin: (state) => state.diary.is_admin,
    }),
  },

  components: {
    "tinymce-editor": Editor,
    "media-picker": MediaPicker,
  },
  data: () => ({
    page: {
      is_loading: false,
      error: null,
    },

    is_saving: false,
    error: null,
    form: false,
    tinymceApiKey: process.env.VUE_APP_TINYMCE_KEY,

    post: {
      header: "",
      text: "",
      children: [],
      categories: [],
      allow_comments: true,
    },

    editor: {
      height: 500,
      menubar: true,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks fullscreen",
        "insertdatetime imagetools media table paste",
      ],
      toolbar:
        "undo redo | " +
        " bold italic underline | forecolor backcolor | alignleft aligncenter " +
        " alignright alignjustify | bullist numlist outdent indent |" +
        " image media",
      content_css: [
        "//fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
      ],
    },
  }),
  created: function () {
    this.start();
  },
  methods: {
    ...mapActions({
      create: "diary/news/create",
      update: "diary/news/update",
      createCategory: "diary/categories/create",
    }),

    removeTagChild(item) {
      const index = this.post.children.indexOf(item.id);
      if (index >= 0) this.post.children.splice(index, 1);
    },
    removeTagCategory(item) {
      const index = this.post.categories.indexOf(item.id);
      if (index >= 0) this.post.categories.splice(index, 1);
    },
    start() {
      if (this.$route.params.id) {
        this.page.id = this.$route.params.id;
        this.load();
      }

      if (this.$route.query.child && !this.$route.params.id) {
        this.post.children.push(this.$route.query.child);
      }
    },
    load() {
      var self = this;
      self.page.is_loading = true;

      blogPostService
        .getPostToForm(self.diary.name, self.page.id)
        .then(function (response) {
          self.page.is_loading = false;
          self.post = response.data.post;
        })
        .catch(function (error) {
          self.page.error = error;
          self.page.is_loading = false;
        });
    },

    save() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        var data = self.getPostData();

        if (!self.page.id) {
          self
            .create(data)
            .then(function (response) {
              self.$successNoty(self.$t("SuccessCreate"));
              self.$router.push(
                "/" + self.diary.name + "/diary/" + response.data.url_name
              );
            })
            .catch(function (error) {
              self.error = error;
              self.is_saving = false;
            });
        } else {
          self
            .update({ id: self.page.id, post: data })
            .then(function () {
              self.is_saving = false;
              self.$successNoty(self.$t("SuccessUpdate"));
            })
            .catch(function (error) {
              self.error = error;
              self.is_saving = false;
            });
        }
      }
    },
    getPostData() {
      var data = {
        header: this.post.header,
        text: this.post.text,
        allow_comments: this.post.allow_comments,
        categories: [],
        children: [],
        tags: this.post.tags,
      };

      if (this.post.children) {
        for (let i = 0; i < this.post.children.length; i++) {
          let id = this.post.children[i].id;

          if (!id) id = this.post.children[i];

          data.children.push(id);
        }
      }
      if (this.post.categories) {
        for (let i = 0; i < this.post.categories.length; i++) {
          let id = this.post.categories[i].id;

          if (!id) id = this.post.categories[i];

          data.categories.push(id);
        }
      }
      return data;
    },
    openGallery() {
      this.$refs.mediaPicker.open();
    },
    selectedMedia(items) {
      if (items.length > 0) {
        for (var i = 0; i < items.length; i++) {
          var obj = items[i];
          var url = obj.image.width_1024;
          this.post.text +=
            "<img src='" +
            url +
            "' alt='" +
            obj.title +
            "' title='" +
            obj.title +
            "' />";
        }
      }
    },
  },
};
</script>